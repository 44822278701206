import { render, staticRenderFns } from "./SkillStubTooltip.vue?vue&type=template&id=1af6b5b2&scoped=true&"
import script from "./SkillStubTooltip.vue?vue&type=script&lang=ts&"
export * from "./SkillStubTooltip.vue?vue&type=script&lang=ts&"
import style0 from "./SkillStubTooltip.vue?vue&type=style&index=0&id=1af6b5b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1af6b5b2",
  null
  
)

export default component.exports