




































































































import Vue from 'vue';

import LoadingErrorable from "@/models/util/LoadingErrorable";
import SkillProvider from "@/api/SkillProvider";

import SpriteIcon from "@/components/util/SpriteIcon.vue";
import Loader from "@/components/util/Loader.vue";
import SkillStubTooltip from "@/components/skill/SkillStubTooltip.vue";

import ISkill from '@/models/skills/ISkill';
import { SkillType } from '@/models/skills/SkillEnums';
import { filters } from '@/filters/Filters';

import { debounce } from 'lodash';

interface IData {
    skill: LoadingErrorable<ISkill, any>;
    awakenedSkill: LoadingErrorable<ISkill, any>;
    subSkill: LoadingErrorable<ISkill[], any>;
    isHover: boolean;
    isShift: boolean;
    hoverIn: () => void;
    hoverOut: () => void;
}

export default Vue.extend({
    data(): IData {
        return {
            skill: new LoadingErrorable(),
            awakenedSkill: new LoadingErrorable(),
            subSkill: new LoadingErrorable(),
            isHover: false,
            isShift: false,
            hoverIn: () => {},
            hoverOut: () => {},
        };
    },
    props: {
        skillId: {
            type: Number as () => number,
        },
        fill: {
            type: Boolean as () => boolean,
        },
        skillData: {
            type: Object as () => ISkill,
        },
        iconOnly: {
            type: Boolean as () => boolean,
        },
        subskills: {
            type: Array as () => number[],
        },
        awakenedSkillId: {
            type: Number as () => number,
        }
    },
    components: {
        SpriteIcon,
        Loader,
        SkillStubTooltip,
    },
    watch: {
        skillId() {
            this.fetchData();
        },
        skillData() {
            this.fetchData();
        },
        awakenedSkillId() {
            this.fetchAwakenData();
        },
        subskills() {
            this.fetchSubskillData();
        },
    },
    mounted() {
        this.hoverIn = debounce(() => {
            this.isHover = true;
        }, 250);
        this.hoverOut = debounce(() => {
            this.isHover = false;
        }, 500);
        this.fetchData();
        this.fetchAwakenData();
        this.fetchSubskillData();
    },
    computed: {
        hasShift(): boolean {
            return !!(this.awakenedSkillId || (this.subskills && this.subskills.length));
        },
        SkillType(): any {
            return SkillType;
        },
    },
    methods: {
        onHoverIn(evt: MouseEvent) {
            this.isHover = true;
            this.isShift = evt.shiftKey;
        },
        onHoverOut(event: MouseEvent) {
            // if (event.)
            this.isHover = false;
        },
        mouseMove(evt: MouseEvent) {
            this.isShift = evt.shiftKey;
        },
        async fetchData() {
            if (this.skillData) {
                this.skill.done(this.skillData);
                this.$emit("done", this.skill.value);
                return;
            }

            this.skill.startLoad();
            try {
                const skillData = await SkillProvider.getSkill(this.skillId);
                this.skill.done(skillData);
                this.$emit("done", this.skill.value);
            } catch (err) {
                // console.log(err);
                this.skill.failed(err);
            }
        },
        async fetchAwakenData() {
            this.awakenedSkill.startLoad();
            try {
                const skillData = await SkillProvider.getSkill(this.awakenedSkillId);
                this.awakenedSkill.done(skillData);
                this.$emit("doneAwakened", this.awakenedSkill.value);
            } catch (err) {
                this.awakenedSkill.failed(err);
            }
        },
        async fetchSubskillData() {
            this.subSkill.startLoad();
            try {
                const ss = [];
                for (const skid of this.subskills) {
                    if (!skid) {
                        continue;
                    }
                    try {
                        ss.push(await SkillProvider.getSkill(skid));
                    } catch (err) {
                        console.error(err);
                    }
                }
                
                this.subSkill.done(ss);
                this.$emit("doneSubskill", this.subSkill.value);
            } catch (err) {
                this.subSkill.failed(err);
            }
        },
        skillTypeToString(type: SkillType): string {
            return filters.skillType(type);
        },
    }
});
